import React, { Component } from "react";
import { LayoutWrapper } from "../../LayoutWrapper";
import { Link } from "react-router-dom";

export class AddMasterData extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddMasterData");
    this.state = {
      editData: {},
      districts: [],
      clusters: [],
      search: {
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.masterFields = [
      {
        type: "text",
        name: "district",
        label: "District Name",
        placeholder: "Enter District Name",
        required: true,
      },
      {
        type: "text",
        name: "clusters",
        label: "No. of Clusters",
        placeholder: "Enter Cluster Code",
        disabled: true,
        required: true,
      },
      {
        type: "text",
        name: "villages",
        label: "No. of Village",
        placeholder: "Enter Village ID",
        disabled: true,
        required: true,
      },
    ];
  }

  get formData() {
    return this.refs.form.form.current.getData();
  }

  componentDidMount() {
    this.fetchDistrict();
    this.getEditData();
  }

  getEditData() {
    if (this.props.urlparams.villages) {
      let data = {
        district: this.props.urlparams.district,
        clusters: this.props.urlparams.clusters,
        villages: this.props.urlparams.villages,
      };
      console.log({ editData: data });
      this.setState({ editData: data });
    }
  }
  deleteDistrict(e, item) {
    e.preventDefault();
    e.stopPropagation();
    let cnfrm = confirm(
      `Do you really want to delete this ${item.label} from the list`
    );
    cnfrm.promise
      .then(() => {
        this.api.deleteDistrict(item.key).then(() => {
          this.fetchDistrict();
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  addDistrict(newrow) {
    // console.log({ addDistrict: newrow });
    this.api.postDistrict(newrow).then(() => {
      this.fetchDistrict();
      let districtVnode = document.querySelector(
        "[name='appinput-district']"
      ).vnode;
      let { values: selected = [] } = districtVnode.refs.select.state;
      if (selected.length) {
        districtVnode.refs.select.clearAll();
      }
      alert("District Added successfully");
    });
  }

  fetchDistrict() {
    let payload = {
      ...this.state.search,
    };
    this.api.getDistrict(payload).then((res) => {
      this.setState({ districts: res.data });
    });
  }

  findDifferentProperties(obj1, obj2) {
    const differentProperties = {};
    let propName = [];

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          propName.push(key);
          differentProperties[key] = {
            oldValue: obj1[key],
            newValue: obj2[key],
          };
        }
      }
    }

    return propName;
  }

  handleSubmit(e, data) {
    let formData = data;
    // console.log("Master form data: ", formData);
    formData = {
      ...formData,
    };
    if (this.props.urlparams.district) {
      // console.log("edit master data");
      let payload = {
        ...formData,
        // cluster: formData.cluster_code,
      };
      console.log({ payload });
      // console.log(this.state.editData);

      const resolveList = () => {
        let url = "/admin/masterData";
        this.props.navigate(url);
        // success("Updated successfully");
      };
      let districtPayload = {
        district: formData.district,
      };
      this.api
        .updateDistrict(districtPayload, {
          district_name: this.state.editData.district,
        })
        .then(resolveList);
    } else {
      let addDistrictPayload = {
        ...formData,
      };
      console.log({ addDistrictPayload });
      this.api.postDistrict(addDistrictPayload).then(() => {
        let url = "/admin/masterData";
        this.props.navigate(url);
        success("District created successfully");
        this.fetchDistrict();
      });
    }
  }

  updateFields(fields) {
    // fields.find((o) => o.name === "district").options =
    //   this.state.districts.options("_id", "district");
    // fields.find((o) => o.name === "cluster_code").options =
    //   this.state.clusters.options();
    return fields;
  }

  getBreadCums() {
    if (this.props.urlparams.district) {
      return `Master Data / Edit District`;
    } else {
      return "Master Data / Add District";
    }
  }
  getBackLink() {
    return "/admin/masterdata";
  }

  render() {
    let { masterFields } = this;
    let { editData } = this.state;
    masterFields = this.updateFields(masterFields);

    masterFields = masterFields?.map((field) => {
      field.defaultValue = field.defaultValue;

      if (![undefined].includes(editData[field.name])) {
        if (field.type == "date") {
          field.defaultValue = editData[field.name].date();
        } else {
          field.defaultValue = editData[field.name];
        }
      }
      return field;
    });
    return (
      <LayoutWrapper
        title="Master Data"
        back={this.getBreadCums()}
        backlink={this.getBackLink()}
      >
        <AppForm
          onSubmit={(...args) => this.handleSubmit(...args)}
          className={"form"}
          autoComplete="off"
          ref="form"
        >
          <div className="row">
            {masterFields
              .filter((f) => {
                return !this.props.urlparams.district
                  ? f.name !== "new_district" && f.name !== "new_cluster"
                  : f;
              })
              .map((field, i) => (
                <GapFields {...{ field, i }} key={i} />
              ))}
          </div>
          <div className="row no-gutters mt-4">
            <button className="btn add-btn col col-md-1 ml-auto">
              {this.props.urlparams.district ? "Update" : "Add"}
            </button>
            <Link
              to={"/admin/masterdata"}
              className="btn btn_primary grey col col-md-1 ms-2 me-2"
            >
              Cancel
            </Link>
          </div>
        </AppForm>
      </LayoutWrapper>
    );
  }
}

export const GapFields = ({
  field,
  i,
  className = "col-12 col-md-3 px-2 ",
}) => (
  <>
    <AppInput
      {...field}
      className={className + (field?.className || "")}
      key={i}
    ></AppInput>
    {i != 0 && i % 3 == 2 && (
      <div className="col-12 col-md-3 px-2" key={"div" + i}></div>
    )}
  </>
);

export default connect(AddMasterData);
