import React, { Component } from "react";
import * as XLSX from "xlsx";
import VillageHeader from "./VillageHeader";
import VillageSearch from "./VillageSearch";
import VillageMoveModal from "./VillageMoveModal";
class VillageList extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("VillageList");
    this.state = {
      rowcount: 0,
      masterData: [],
      search: {
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
        district: "",
        cluster: "",
        search: "",
      },
      editData: {},
      viewMode: false,
      editMode: false,
      popupfields: {},
      popupdata: {},
      deleteAction: "temp",
      last_working_day: new Date(),
      disabled_remarks: "",
      selectedRowKeys: [],
      selectedRows: [],
      selectedVillageIds: [],
      clusterValue: "",
      clustersList: [],
      isDisabled: true,
    };
    this.actionFormShow = false;
    this.columns = [
      {
        dataIndex: "id",
        display: false,
      },
      {
        title: "Village ID",
        dataIndex: "village_id",
      },

      {
        title: "Village Name",
        dataIndex: "village_name",
      },
    ];
    this.modalFields = [
      {
        type: "text",
        name: "from_cluster",
        label: "From Cluster",
        required: true,
        disabled: true,
        customcolspan: "12",
      },
      {
        type: "select",
        name: "cluster",
        label: "To Cluster",
        options: [],
        required: true,
        customcolspan: "12",
      },
    ];
    this.moveVillageModalRef = React.createRef();
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  addModalFields(values) {
    let fields = (this.modalFields || []).reduce(
      (o, n) => ({ ...o, [n.name]: n }),
      {}
    );
    let formdata = Object.map(fields, (v, k) => "");
    // console.log("formdata: ", fields, formdata);
    this.setState({
      popupfields: fields,
      popupdata: formdata,
    });
  }

  handleClusterChange(value) {
    console.log({ handleClusterChange: value[0]?.key });
    this.setState({
      clusterValue: value[0]?.key,
    });
  }

  fetchList(params = {}) {
    let { search, masterData } = this.state;
    if (search !== undefined && search.district) {
      let payload = {
        ...Object.except(this.state.search, ["district", "search", "cluster"]),
      };

      this.api
        .getVillageList(payload, {
          district: this.state.search.district,
          cluster: this.state.search.cluster,
        })
        .then(({ data, total }) => {
          console.log({ getVillageList: data });
          // if (!this.isAdmin) {
          //   let dt = this.props.store.user.district;
          //   let fdata = data?.filter((r) => r.district === dt);
          //   this.setState({ masterData: fdata });
          // } else {
          // }
          this.setState({ masterData: data, rowcount: total });
        });
    } else {
      console.log("Reset");
      this.setState({ masterData: [], rowcount: 0, isDisabled: true });
    }

    // this.api.getVillageListMokeData().then((data) => {
    //   console.log({ getVillageListMokeData: data });
    //   this.setState({ masterData: data });
    // });
  }

  fetchAllClusters() {
    this.api.getAllClusters().then((data) => {
      console.log("Fetching all clusters: ", data);
      this.setState({ clustersList: data });
    });
  }

  selectRow = (record, key) => {
    const selectedRowKeys = [...this.state.selectedRowKeys];
    // console.log({ selectedRowKeys: selectedRowKeys });

    if (selectedRowKeys.indexOf(record.village_id) >= 0) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.village_id), 1);
    } else {
      selectedRowKeys.push(record.village_id);
    }
    let selectedRow = this.state.masterData
      .filter((row) => selectedRowKeys.includes(row.village_id))
      .map((row) => row.village_id);
    console.log({ selectRow: selectedRow });
    if (selectedRow.length > 0) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    this.setState({ selectedRowKeys, selectedVillageIds: selectedRow });
  };

  onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    // console.log({ selectedRowKeys, selectedRows });
    let selectedRow = this.state.masterData
      .filter((row) => selectedRowKeys.includes(row.village_id))
      .map((row) => row.village_id);
    console.log({ onSelectedRowKeysChange: selectedRow });
    if (selectedRow.length > 0) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
    this.setState({
      selectedRowKeys,
      selectedRows,
      selectedVillageIds: selectedRow,
    });
  };

  componentDidMount() {
    console.log({ componentDidMount: "VillageList" });
    this.fetchList();
    this.fetchAllClusters();
  }

  onSearch(data) {
    console.log({ data });
    // this.setState({ search: data }, () => {
    //   this.fetchList();
    // });

    if (data !== undefined) {
      let searchPayload = {
        ...this.state.search,
      };
      searchPayload.district = data.district;
      searchPayload.cluster = data.cluster;
      searchPayload.search = data.district;
      console.log({ searchPayload });

      this.setState({ search: { ...searchPayload } }, () => {
        this.fetchList(data);
      });
    } else {
      let resetSearchPayload = {
        ...this.state.search,
        district: "",
      };
      this.setState(
        { search: { ...resetSearchPayload }, masterData: [] },
        () => {
          this.fetchList();
        }
      );
    }
  }

  onFilterChange(field, value, firstDate, lastDate, displayValue) {
    if (field.name == "lastdate") {
      // console.log(value.toISOString().substring(0, 10));
      this.setState({
        ...this.state,
        last_working_day: value,
      });
    }
    if (field.name == "remarks") {
      this.setState({
        ...this.state,
        disabled_remarks: document.querySelector(`input[name=${field.name}]`)
          .value,
      });
    }
  }

  onPageChange(pageData) {
    console.log({ pageData });
    let payload = this.props.inactive === true ? { status: "disabled" } : {};
    this.setState(
      {
        search: {
          ...this.state.search,
          page: pageData.current,
          limit: pageData.pageSize || pageData.defaultPageSize || 25,
        },
      },
      () => this.fetchList(payload)
    );
  }

  editVillageData(record) {
    console.log({ editVillageData: record });
    let { district, cluster } = this.state.search;
    let url =
      "/admin/masterdata/village/addvillagedata" +
      Object.encodeuri({
        district: district,
        cluster_code: cluster,
        village_id: record.village_id,
        village_name: record.village_name,
      });
    this.props.navigate(url);
  }

  deleteVillageData(value) {
    let payload = {
      village_id: value.village_id,
    };

    confirm(`Are you want to delete this Village.?`).promise.then(() => {
      this.api.deleteMasterVillage(payload).then(() => {
        this.fetchList();
      });
    });
  }

  openWEmodal() {
    this.moveVillageModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#move_village_modal #bsmodalclose").click();
  }

  handleSubmit(event, data, form) {
    event.preventDefault();
    // console.log("formData", data, form);

    const resolveList = () => {
      success("Village moved successfully");
      form.reset();
      this.closeModal();
      this.fetchList();
    };
    let payload = {
      ...data,
      village_ids: this.state.selectedVillageIds,
    };
    console.log({ payload });
    this.props.api.moveVillage(payload).then(resolveList);
  }

  getDownloadData() {
    let { rowcount, search } = this.state;
    console.log(rowcount, search);
    this.props.api
      .getVillageList(
        {
          ...Object.except(this.state.search, ["district", "search"]),
          page: 1,
        },
        {
          district: this.state.search.district,
          cluster: this.state.search.cluster,
        }
      )
      .then((results) => {
        let totalrows = results.data;
        totalrows = totalrows.map((v) => {
          return {
            village_id: v.village_id,
            village_name: v.village_name,
          };
        });
        console.log({ results, totalrows });
        this.handleDownload(totalrows);
      });
  }

  handleDownload = (totaldata = []) => {
    const { data: pagedata, columns } = this.state;
    let data = totaldata.length ? totaldata : pagedata;
    const tabledata = data.reduce((arr, row) => {
      let mrow = Object.only(row, ["village_id", "village_name"]);
      arr.push(mrow);
      return arr;
    }, []);
    let groups = {};
    let addeddata = tabledata.reduce((arr, trow, index) => {
      let row = data[index];
      let isRowHasArray = Object.values(trow).reduce(
        (flag, value) => flag || value instanceof Array,
        false
      );
      let arrRemovedRow = Object.filter(trow, (v) => !(v instanceof Array));
      arrRemovedRow = Object.filter(
        arrRemovedRow,
        (v, k) => !["projectId", "formId"].includes(k)
      );
      arr.push(arrRemovedRow);
      if (isRowHasArray) {
        let formId = row._id;
        let formName = row.formName;
        let arrayValues = Object.filter(trow, (v) => v instanceof Array);
        Object.entries(arrayValues).map(([key, values], j) => {
          groups[formId + "-" + j] = groups[formId + "-" + j] || [];
          values.map((value) => {
            groups[formId + "-" + j].push({
              id: formId,
              formName,
              subcategory: key,
              ...value,
            });
          });
        });
      }
      return arr;
    }, []);
    let groupsarr = Object.entries(groups).reduce((arr, [key, values]) => {
      arr = arr.concat(values);
      return arr;
    }, []);
    // console.log("data", data, { tabledata, addeddata, groups, groupsarr });
    // console.log("columns", columns);
    let mainHeaders = Object.keys(addeddata[0] || {});

    let mainData = addeddata.map((v) => mainHeaders.map((k) => v[k]));
    let groupHeaders = Object.keys(groupsarr[0] || {});
    let groupData = groupsarr.map((v) => groupHeaders.map((k) => v[k]));
    let concatedHeader = [...mainHeaders, ...groupHeaders.slice(2)];
    let concatedData = mainData.reduce((c, r) => {
      c.push(r);
      let grouprows = groupData.filter((o) => o[0] == r[0]);
      if (grouprows.length) {
        grouprows.map((gr) => {
          c.push([...r, ...gr.slice(2)]);
        });
      }
      return c;
    }, []);
    concatedHeader = concatedHeader.map((item) =>
      item.spaceCase("_").toTitleCase()
    );
    const exportData = [concatedHeader, ...concatedData];
    console.log("mainheaders", {
      mainHeaders,
      addeddata,
      tabledata,
      exportData,
      concatedHeader,
    });
    //return;

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(exportData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate a unique filename for the Excel sheet
    const filename = `masterdata_${new Date().getTime()}.xlsx`;

    // Export the workbook as an Excel file

    XLSX.writeFile(workbook, filename);
  };

  updateFields(fields) {
    // console.log({ updateFields: fields });
    if (Object.keys(fields).length > 0) {
      fields["from_cluster"].defaultValue = this.state.clusterValue;
      fields["cluster"].options = this.state.clustersList
        .map((v) => v.cluster)
        .options();
      return fields;
    }
  }
  // getClusters(data) {
  //   console.log("clusters from search: ", data);
  //   this.setState({ clustersList: data });
  // }
  render() {
    let {
      masterData,
      selectedRowKeys,
      editData,
      popupdata,
      popupfields,
      editMode,
      viewMode,
    } = this.state;
    let { columns, isAdmin, modalFields } = this;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectedRowKeysChange,
    };
    // console.log({ popupfields });
    popupfields = this.updateFields(popupfields);
    return (
      <>
        {isAdmin && (
          <VillageHeader
            searchData={this.state.search}
            handleDownloadList={() => this.getDownloadData()}
          />
        )}
        <VillageSearch
          inactive={this.props.inactive}
          addModalFields={(...args) => this.addModalFields(...args)}
          moveVillageModalRef={this.moveVillageModalRef}
          onSearch={(...arg) => this.onSearch(...arg)}
          handleClusterChange={(...args) => this.handleClusterChange(...args)}
          isDisabled={this.state.isDisabled}
          // getClusters={(...args) => this.getClusters(...args)}
        />
        <div className="row mb-4">
          <div className="col">
            <AppTable
              data={masterData}
              columns={columns}
              reorder={true}
              onChange={(...arg) => this.onPageChange(...arg)}
              total={this.state.rowcount}
              // editable={false}
              // deletable={false}
              viewable={false}
              {...(isAdmin
                ? {
                    editAction: (v) => this.editVillageData(v),
                    deleteAction: (v) => this.deleteVillageData(v),
                    onRowClick: (record, rowIndex) => {
                      return {
                        onClick: (event) =>
                          this.selectRow(record, record.village_id),
                      };
                    },
                    checkbox: true,
                    rowSelection: rowSelection,
                  }
                : {
                    editable: false,
                    deletable: false,
                  })}
              pageSize={10}
            ></AppTable>
          </div>
        </div>

        <VillageMoveModal
          id="move_village_modal"
          modalSize="default"
          title={`${editMode ? "Move to Cluster" : "Move to Cluster"}`}
          submitText={`${editMode ? "Update" : "Save"}`}
          editdata={editData}
          data={popupdata}
          fields={popupfields}
          disableallfields={viewMode ? true : false}
          // disablefields={["email"]}
          onSubmit={(...args) => this.handleSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
              editData: {},
              editMode: false,
              viewMode: false,
            })
          }
        />
      </>
    );
  }
}

export default connect(VillageList);
