import { useEffect } from "react";
import { useState } from "react";
import SubCategoryField from "./SubCategoryField";

function filterDcConstraints(props, field) {
  let constraints = _enum.FIELD_DC_CONSTRAINTS;
  let FIELD_DC_CONSTRAINTS = [];
  let isFreeText = ["text", "textarea"].includes(field?.fieldType);
  let isDate = ["date"].includes(field?.fieldType);
  if (isFreeText) {
    FIELD_DC_CONSTRAINTS = constraints.exclude(
      ["future_dates_only", "past_dates_only"],
      "key"
    );
  } else if (isDate) {
    FIELD_DC_CONSTRAINTS = constraints.only(
      ["mandatory", "future_dates_only", "past_dates_only"],
      "key"
    );
  } else {
    FIELD_DC_CONSTRAINTS = constraints.only(["mandatory", "optional"], "key");
  }
  return FIELD_DC_CONSTRAINTS;
}

function hasPossibleOptions(props, field) {
  return ["select", "checkbox", "radio"].includes(field?.fieldType);
}

function handleInputChange(props, field, name, node, event) {
  console.log("handleInputChange", event);
  let value = "";
  if (["select"].includes(node.type)) {
    value = node.multi
      ? (event || []).map((o) => o?.key)
      : (event || [])[0]?.key;
  } else {
    value = event.target.value;
  }
  let key = name.split(".").reverse()[0];

  if (key == "possibleValues") {
    value = value
      .split(",")
      .filter((v) => v)
      .join(",");
  }

  node.setData((state) => ({ ...state, [key]: value }));
}

const FiledRow = connect((props) => {
  const index = props.index;
  const disabled = Object.values(_enum.MANDATORY_DC_OBJS).map((o) => o.name);
  const parentIndex = props.parentIndex;
  const grandParentIndex = props.grandParentIndex;
  const [field, setField] = useState(props.field);
  useEffect(() => {
    setField(props.field);
  }, [props.field]);
  const FIELD_DC_CONSTRAINTS = filterDcConstraints(props, field);
  const needPossibleOptions = hasPossibleOptions(props, field);

  const possiblevalues = props?.possibleValues;
  console.log({ field, possiblevalues });

  let isSelect = (field?.fieldType || []).includes("select");
  let isNumeric = (field?.fieldConstraint || []).includes("numeric");
  let isLength = (field?.fieldConstraint || []).includes("length");
  let hasSubCategory = ["true"].includes(field.subCategory + "");
  let hasFollowUp = field.followUp === undefined ? true : field.followUp;
  const node = { data: field, setData: setField };
  const canDisable = disabled.includes(field.name || field.label);
  const canEdit = !(
    props.urlparams.readonly || disabled.includes(field.name || field.label)
  );
  useEffect(() => {
    console.log({
      data: field,
      props,
      isNumeric,
      isSelect,
      hasSubCategory,
      canEdit,
    });
  }, [field]);
  props.setNode(
    {
      props,
      field,
    },
    "SubCategoryFieldRow2" + index + parentIndex
  );
  // const getName = (key) =>
  //   `fields.${
  //     props.mixin.params().id ? grandParentIndex : 2
  //   }.categoryInfo.${parentIndex}.categoryInfo.${index}.${key}`;
  const getName = (key) =>
    `fields.${grandParentIndex}.categoryInfo.${parentIndex}.categoryInfo.${index}.${key}`;
  return (
    <div
      className={Object.className({
        // "flex flex-wrap gap-4": true,
        "border-4 border-solid border-gray-200": isNumeric && hasSubCategory,
        // "border-4 border-solid border-gray-200": isSelect && hasSubCategory,
        "-mt-6 bg-gray-200 p-3 level2": true,
      })}
    >
      <div className="flex">
        <h2 className="fs-5">{possiblevalues[index] || "-"}</h2>
      </div>
      <div className="flex flex-wrap gap-4 bg-gray-200 px-2" key={field[0]?.id}>
        <AppInput
          label="Follow Up"
          type="select"
          options={_enum.DC_SUBCATEGORY}
          name={getName("followUp")}
          className="grow"
          defaultValue={hasFollowUp}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              getName("followUp"),
              { type: "select", ...node },
              ...args
            )
          }
        ></AppInput>
        <AppInput
          label="Field Label"
          type="text"
          name={getName("label")}
          className="grow"
          required={hasFollowUp}
          disabled={!hasFollowUp}
          defaultValue={field?.label}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              getName("label"),
              { type: "text", ...node },
              ...args
            )
          }
        ></AppInput>
        <AppInput
          label="Field Type"
          type="select"
          name={getName("fieldType")}
          options={_enum.FIELD_DC_TYPES}
          className="grow"
          required={hasFollowUp}
          disabled={!hasFollowUp}
          defaultValue={field?.fieldType}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              getName("fieldType"),
              { type: "select", ...node },
              ...args
            )
          }
        ></AppInput>
        {needPossibleOptions && (
          <AppInput
            label="Possible Values"
            type="text"
            name={getName("possibleValues")}
            className="grow"
            required={hasFollowUp}
            disabled={!hasFollowUp}
            note="possible value should be comma separated"
            defaultValue={field?.possibleValues}
            onChange={(...args) =>
              mixins.debounce(() =>
                handleInputChange(
                  props,
                  field,
                  getName("possibleValues"),
                  { type: "text", ...node },
                  ...args
                )
              )
            }
          ></AppInput>
        )}
        {FIELD_DC_CONSTRAINTS && (
          <AppInput
            label="Field Constraints"
            type="select"
            name={getName("fieldConstraint")}
            className="grow"
            required={hasFollowUp}
            disabled={!hasFollowUp}
            multi={true}
            options={FIELD_DC_CONSTRAINTS}
            defaultValue={field?.fieldConstraint || []}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                getName("fieldConstraint"),
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isNumeric && (
          <AppInput
            label="Sub Category"
            type="select"
            name={getName("subCategory")}
            options={_enum.DC_SUBCATEGORY}
            className="grow"
            defaultValue={!!(field?.subCategory || false)}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                getName("subCategory"),
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isLength && (
          <>
            <AppInput
              label="Min"
              type="number"
              name={getName("constraintMetadata.length.min")}
              className="mw-100px"
              defaultValue={field?.constraintMetadata?.length?.min || ""}
              readOnly={!canEdit}
            ></AppInput>
            <AppInput
              label="Max"
              type="number"
              name={getName("constraintMetadata.length.max")}
              className="mw-100px"
              defaultValue={field?.constraintMetadata?.length?.max || ""}
              readOnly={!canEdit}
            ></AppInput>
          </>
        )}
      </div>
      {isNumeric && hasSubCategory && (
        <SubCategoryField
          list={field.categoryInfo || []}
          index={index}
          field={field}
        />
      )}
    </div>
  );
}, "SubCategoryFieldRow2");

const addRow = ({ rows, setRows, event }) => {
  let item = { id: +Math.random().toString().slice(2) };
  setRows((state) => [...state, item]);
};

const createDefaultList = (length) => {
  let defaultlist = [];

  for (let i = 0; i < length; i++) {
    defaultlist.push([{ id: +Math.random().toString().slice(2) }]);
  }

  return defaultlist;
};

export default connect(function (props) {
  let possibleValuesArr = Array.isArray(props?.field?.possibleValues)
    ? props?.field?.possibleValues
    : props?.field?.possibleValues?.split(",").map((item) => item.trim()) || [];

  let defaultlist = createDefaultList(possibleValuesArr?.length);

  const getList = () =>
    (props?.list?.length ? props?.list : defaultlist) || defaultlist;
  const [rows, setRows] = useState(getList());
  const [possiblevalues, setPossiblevalues] = useState(possibleValuesArr);

  useEffect(() => {
    if (
      props.list instanceof Array &&
      props.list.length &&
      props.list.length >= rows.length
    ) {
      setRows(props.list);
    }
  }, [props.list]);
  console.log({ props, rows, possiblevalues, defaultlist });

  let node = { rows, setRows, possiblevalues, setPossiblevalues };
  console.log({ rows });

  props.setNode(node);
  return (
    <>
      {rows?.map((field, i) => (
        <>
          <FiledRow
            {...(props.mixin.params().id
              ? {
                  field: field,
                }
              : { field: field })}
            index={i}
            parentIndex={props.index}
            grandParentIndex={props.parentIndex}
            possibleValues={possiblevalues}
          />
        </>
      ))}
    </>
  );
}, "SubCatgoryList");
