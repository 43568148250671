import { useEffect } from "react";
import { useState } from "react";
import SubCategoryField from "./SubCategoryField";
import SubCategoryDropDownField1 from "./SubCategoryDropDownField1";
import TableFields from "./TableFields";

function filterDcConstraints(props, field) {
  let constraints = _enum.FIELD_DC_CONSTRAINTS;
  let FIELD_DC_CONSTRAINTS = [];
  let isFreeText = ["text", "textarea"].includes(field.fieldType);
  let isDate = ["date"].includes(field.fieldType);
  // console.log("field.fieldType,",field.fieldType);
  if (isFreeText) {
    FIELD_DC_CONSTRAINTS = constraints.exclude(
      ["future_dates_only", "past_dates_only"],
      "key"
    );
  } else if (isDate) {
    FIELD_DC_CONSTRAINTS = constraints.only(
      ["mandatory", "future_dates_only", "past_dates_only", "optional"],
      "key"
    );
  } else {
    FIELD_DC_CONSTRAINTS = constraints.only(["mandatory", "optional"], "key");
  }
  return FIELD_DC_CONSTRAINTS;
}

function hasPossibleOptions(props, field) {
  return ["select", "checkbox", "radio"].includes(field.fieldType);
}

function hasRowsCols(props, field) {
  return ["table"].includes(field.fieldType);
}

function handleInputChange(props, field, name, node, event) {
  // console.log(
  //   "handleInputChange",
  //   { name, node, event, fieldConstraint: field?.fieldConstraint },
  //   "FiledRow" + props.index
  // );
  let value = "";
  if (["select"].includes(node.type)) {
    value = node.multi
      ? (event || []).map((o) => o?.key)
      : (event || [])[0]?.key;
  } else {
    value = event.target.value;
  }
  let key = name.split(".").reverse()[0];

  if (key == "possibleValues") {
    value = value
      .split(",")
      .filter((v) => v)
      .join(",");
  }

  node.setData((state) => ({ ...state, [key]: value }));
}

const FiledRow = connect((props) => {
  const index = props.index;
  const disabled = Object.values(_enum.MANDATORY_DC_OBJS).map((o) => o.name);
  const [field, setField] = useState(props.field);
  const FIELD_DC_CONSTRAINTS = filterDcConstraints(props, field);
  const needPossibleOptions = hasPossibleOptions(props, field);
  const needRowsCols = hasRowsCols(props, field);
  console.log({ CategoryFields: field });

  let isCheckbox = (field?.fieldType || []).includes("checkbox");
  let isSelect = (field?.fieldType || []).includes("select");
  let isRadio = (field?.fieldType || []).includes("radio");
  let isTable = (field?.fieldType || []).includes("table");
  let isNumeric = (field?.fieldConstraint || []).includes("numeric");
  let isLength = (field?.fieldConstraint || []).includes("length");
  let hasSubCategory = ["true"].includes(field.subCategory + "");
  // let hasTable = ["true"].includes(field.subTable + "");

  let hasTable = false;
  if (props.mixin.params().id) {
    hasTable = field?.rowMetadata && field.columnMetadata;
  } else {
    hasTable =
      Object.keys(field).includes("totalRows") &&
      Object.keys(field).includes("totalCols");
  }

  const node = { data: field, setData: setField };
  const canDisable = disabled.includes(field.name || field.label);
  const canEdit = !(
    props.urlparams.readonly || disabled.includes(field.name || field.label)
  );
  const canDelete = canEdit;
  // useEffect(() => {
  //   console.log("Changes After Field Changed in ", "FiledRow" + index, {
  //     field,
  //   });
  // }, [field]);
  useEffect(() => {
    console.log("Field Changed in ", "FiledRow" + index, {
      field: props.field,
    });
    setField(props.field);
  }, [props.field]);
  const deleteRow = (e, index) => {
    props.onDelete && props.onDelete(e, index);
  };
  props.setNode(
    {
      props,
      field,
    },
    "FiledRow" + index
  );
  return (
    <>
      <div
        key={field.id}
        className={Object.className({
          "flex flex-wrap gap-4": true,
          "border-4 border-solid border-gray-200": isNumeric && hasSubCategory,
          "border-4 border-solid border-gray-200": isSelect && hasSubCategory,
          "border-4 border-solid border-gray-200": isRadio && hasSubCategory,
          "border-4 border-solid border-gray-200": isCheckbox && hasSubCategory,
        })}
      >
        <AppInput
          label="Field Label"
          type="text"
          name={`fields.${index}.label`}
          className="grow"
          required={true}
          defaultValue={field.label}
          readOnly={!canEdit}
          disabled={canDisable}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              `fields.${index}.label`,
              { type: "text", ...node },
              ...args
            )
          }
        ></AppInput>
        <AppInput
          label="Field Type"
          type="select"
          name={`fields.${index}.fieldType`}
          options={_enum.FIELD_DC_TYPES}
          className="grow"
          required={true}
          defaultValue={field.fieldType}
          readOnly={!canEdit}
          disabled={canDisable}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              `fields.${index}.fieldType`,
              { type: "select", ...node },
              ...args
            )
          }
        ></AppInput>
        {needPossibleOptions && (
          <AppInput
            label="Possible Values"
            type="text"
            name={`fields.${index}.possibleValues`}
            className="grow"
            required={true}
            note="possible value should be comma separated"
            defaultValue={field.possibleValues}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              mixins.debounce(() =>
                handleInputChange(
                  props,
                  field,
                  `fields.${index}.possibleValues`,
                  { type: "text", ...node },
                  ...args
                )
              )
            }
          ></AppInput>
        )}
        {needRowsCols && (
          <>
            <AppInput
              label="Rows"
              type="number"
              name={`fields.${index}.totalRows`}
              className="grow"
              required={true}
              defaultValue={field.totalRows}
              readOnly={!canEdit}
              disabled={canDisable}
              onChange={(...args) =>
                mixins.debounce(() =>
                  handleInputChange(
                    props,
                    field,
                    `fields.${index}.totalRows`,
                    { type: "number", ...node },
                    ...args
                  )
                )
              }
            ></AppInput>
            <AppInput
              label="Columns"
              type="number"
              name={`fields.${index}.totalCols`}
              className="grow"
              required={true}
              defaultValue={field.totalCols}
              readOnly={!canEdit}
              disabled={canDisable}
              onChange={(...args) =>
                mixins.debounce(() =>
                  handleInputChange(
                    props,
                    field,
                    `fields.${index}.totalCols`,
                    { type: "number", ...node },
                    ...args
                  )
                )
              }
            ></AppInput>
          </>
        )}
        {FIELD_DC_CONSTRAINTS && !isTable && (
          <AppInput
            label="Field Constraints"
            type="select"
            name={`fields.${index}.fieldConstraint`}
            multi={true}
            options={FIELD_DC_CONSTRAINTS}
            className="grow"
            defaultValue={field.fieldConstraint}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                `fields.${index}.fieldConstraint`,
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isNumeric && (
          <AppInput
            label="Sub Category"
            type="select"
            name={`fields.${index}.subCategory`}
            options={_enum.DC_SUBCATEGORY}
            className="grow"
            defaultValue={!!(field?.subCategory || false)}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                `fields.${index}.subCategory`,
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isSelect && (
          <AppInput
            label="Sub Category"
            type="select"
            name={`fields.${index}.subCategory`}
            options={_enum.DC_SUBCATEGORY}
            className="grow"
            defaultValue={!!(field?.subCategory || false)}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                `fields.${index}.subCategory`,
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isCheckbox && (
          <AppInput
            label="Sub Category"
            type="select"
            name={`fields.${index}.subCategory`}
            options={_enum.DC_SUBCATEGORY}
            className="grow"
            defaultValue={!!(field?.subCategory || false)}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                `fields.${index}.subCategory`,
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isRadio && (
          <AppInput
            label="Sub Category"
            type="select"
            name={`fields.${index}.subCategory`}
            options={_enum.DC_SUBCATEGORY}
            className="grow"
            defaultValue={!!(field?.subCategory || false)}
            readOnly={!canEdit}
            disabled={canDisable}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                `fields.${index}.subCategory`,
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isLength && (
          <>
            <AppInput
              label="Min"
              type="number"
              name={`fields.${index}.constraintMetadata.length.min`}
              className="mw-100px"
              defaultValue={field?.constraintMetadata?.length?.min || ""}
              readOnly={!canEdit}
            ></AppInput>
            <AppInput
              label="Max"
              type="number"
              name={`fields.${index}.constraintMetadata.length.max`}
              className="mw-100px"
              defaultValue={field?.constraintMetadata?.length?.max || ""}
              readOnly={!canEdit}
            ></AppInput>
          </>
        )}
        {canDelete ? (
          <button
            type="button"
            onClick={(e) => deleteRow(e, index)}
            className="btn hover:bg-red-800 bg-red-500 m-auto text-white"
          >
            <i className="fa fa-trash-o text-error" />
          </button>
        ) : (
          <span className="inline-block w-12 h-12"></span>
        )}
      </div>
      {isNumeric && hasSubCategory && (
        <SubCategoryField
          list={field.categoryInfo || []}
          index={index}
          field={field}
        />
      )}
      {isTable && hasTable && (
        <TableFields
          rowMetadata={field.rowMetadata || []}
          columnMetadata={field.columnMetadata || []}
          index={index}
          field={field}
        />
      )}
      {isSelect && hasSubCategory && (
        <SubCategoryDropDownField1
          list={field.categoryInfo || []}
          index={index}
          field={field}
        />
      )}
      {isCheckbox && hasSubCategory && (
        <SubCategoryDropDownField1
          list={field.categoryInfo || []}
          index={index}
          field={field}
        />
      )}
      {isRadio && hasSubCategory && (
        <SubCategoryDropDownField1
          list={field.categoryInfo || []}
          index={index}
          field={field}
        />
      )}
    </>
  );
}, "CategoryFieldFiledRow");

const addRow = ({ rows, setRows, event }) => {
  let item = { id: +Math.random().toString().slice(2) };
  setRows((state) => [...state, item]);
};

const deleteRow = (e, index, { rows, setRows, setRender, event }) => {
  setRows((rows) => {
    return [...rows.filter((v, i) => i != index)];
  });
};

export default connect(function (props) {
  const [rows, setRows] = useState(props.list || []);
  useEffect(() => {
    if (props.list instanceof Array) {
      setRows(props.list);
    }
  }, [props.list]);
  props.setNode({
    props,
    rows,
  });
  let node = { rows, setRows };
  console.log("CategoryFields", rows, props);
  return (
    <>
      {rows.map((field, i) => (
        <>
          <FiledRow
            field={field}
            index={i}
            onDelete={(e, index) => deleteRow(e, index, { ...node, event: e })}
          />
        </>
      )) || ""}
      <div>
        {!props.urlparams.readonly && (
          <button
            className="btn bg-transparent flex gap-2 text-primary"
            type="button"
            onClick={(e) => addRow({ rows, setRows, event: e })}
          >
            <i className="fa fa-plus m-auto" />
            <span className="font-bold">Add More Fields</span>
          </button>
        )}
      </div>
    </>
  );
}, "CategoryFields");
