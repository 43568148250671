import React, { Component } from "react";

export class ExportDcModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.columns = [
      {
        dataIndex: "type",
        title: "Type",
        render: (text, record) => {
          return <>{text || "-"}</>;
        },
      },
      {
        dataIndex: "fileUrl",
        title: "URL",
        render: (text, record) => {
          return (
            <span className="text_truncate long cursor-pointer text-primary">
              {text}
            </span>
          );
        },
      },
    ];
  }
  render() {
    let { props, columns } = this;
    let { id, onClose, data } = props;
    return (
      <AppModalBs
        id={id}
        title="Export Data"
        contentClass={"w-3/6 m-auto"}
        onClose={onClose}
      >
        <AppForm>
          <div className="row">
            <div className="col-12">
              <AppTable
                data={data}
                columns={columns}
                deletable={false}
                editable={false}
                viewable={false}
                copytoclipboard={true}
                copytoclipboardFlag="fileUrl"
                showPagination={false}
              ></AppTable>
            </div>
          </div>
        </AppForm>
      </AppModalBs>
    );
  }
}

export default ExportDcModal;
