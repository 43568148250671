export default {
  apiCreateDataCollectionForm(data) {
    console.log({ apiCreateDataCollectionForm: data });

    const processCategoryInfo = (categoryInfo = []) => {
      return categoryInfo.map(category => {
        const hasSubCategory = category?.subCategory === "true";
        const hasFollowUp = category?.followUp === "true";

        return {
          ...category,
          followUp: ["select", "checkbox", "radio"].includes(category?.fieldConstraint) ? hasFollowUp : category?.followUp === "No" || category?.followUp === "false" ? false : true,
          fieldConstraint: category?.fieldConstraint ? category.fieldConstraint.split(',') : [],
          constraintMetadata: category?.constraintMetadata || {},
          possibleValues: (category?.possibleValues || "").split(',').map(v => v.trim()).filter(Boolean),
          subCategory: hasSubCategory,
          ...(hasSubCategory && { categoryInfo: processCategoryInfo(category.categoryInfo) })
        };
      });
    };

    let payload = {
      formName: data.formName,
      role: data?.role?.split(','),
      projectId: data.projectId,
      projectName: data.projectName,
      language: data.language.split(",").map((lang) => lang.trim()),
      formDescription: data.formDescription,
      updatedBy: data.updatedBy,
      fields: Object.values(data?.fields || [])
        .filter(field => field)
        .map(field => {
          const hasSubCategory = field?.subCategory === "true";

          return {
            label: field.label,
            fieldType: field.fieldType,
            possibleValues: (field?.possibleValues || "").split(',').map(v => v.trim()).filter(Boolean),
            fieldConstraint: field?.fieldConstraint ? field.fieldConstraint.split(',') : [],
            constraintMetadata: field?.constraintMetadata || {},
            ...field?.fieldType === "table" ? {
              rowMetadata: field?.rowMetadata?.map(o => {
                return {
                  ...o,
                  rowConstraint: o?.rowConstraint?.split(",")
                }
              }) || [],
              columnMetadata: field?.columnMetadata?.map(o => {
                return {
                  ...o,
                  columnConstraint: o?.columnConstraint?.split(",")
                }
              }) || [],
            } : {},
            subCategory: hasSubCategory,
            ...(hasSubCategory && { categoryInfo: processCategoryInfo(field.categoryInfo) })
          };
        })
    };

    return this.request(this.api().dataCollectionForm, payload).exec().log().get();
  },

  editDCForm(data, urldata = mixins.params()) {
    let payload = {
      role: data.role,
      language: data.language.split(",").map(lang => lang.trim()),
      fields: Object.values(data?.fields || []).map((field) => {
        // const fieldConstraint = (['checkbox', 'radio', 'select', 'file', 'geolocation', 'date'].includes(field.fieldType)) ? ['mandatory'] : (field.fieldConstraint || '').split(',');
        return {
          label: field.label,
          fieldType: field.fieldType,
          // fieldConstraint: fieldConstraint,
          fieldConstraint: (field?.fieldConstraint || '').split(','),
        };
      })
    };
    return this.request(this.api().updatedataCollectionForm, payload).urltransform(urldata).exec().log().get();
  },

  apiDataCollectionFormList(data) {
    let payload = {
      ...data
    };
    console.log("apiDataCollectionForm: ", payload);
    return this.request(this.api().dataCollectionList, payload).exec().log()
      .mapresults((response) => {
        return {
          data: response.data.records.map((form) => ({
            id: form._id,
            formName: form.formName,
            role: form?.role instanceof Array ? form.role.join(', ') : form.role,
            language: form.language.map(lang => _enum.APPLICANT_DC_LANGUAGE_LIST.find(v => v.key == lang)?.label || "")
              .join(","),
            projectId: form.projectId,
            updatedBy: form.updatedBy,
            projectName: form.project_name,
            projectManagerName: form.project_managers?.map(v => v.employee_name).join(', '),
            createdOn: form.createdOn,
            updatedOn: form.updatedOn,
            numberOfResponse: form.numberOfResponse,
            disable: form?.disable,
            exportFileCreationDTM: form.exportFileCreationDTM,
            exportFileDownload: form.exportFileDownload,
            exportFiles: form?.exportFiles
          })),
          total: response.data.pagination[0]?.totalRecords || 0
        }
      })
      .get();
  },

  apiUpdateDataCollectionForm(data, urldata = mixins.params()) {

    const processCategoryInfo = (categoryInfo = []) => {
      return categoryInfo.map(category => {
        const hasSubCategory = category?.subCategory === "true";
        const hasFollowUp = category?.followUp === "true";

        return {
          ...category,
          followUp: ["select", "checkbox", "radio"].includes(category?.fieldConstraint) ? hasFollowUp : category?.followUp === "No" || category?.followUp === "false" ? false : true,
          fieldConstraint: category?.fieldConstraint ? category.fieldConstraint.split(',') : [],
          constraintMetadata: category?.constraintMetadata || {},
          possibleValues: (category?.possibleValues || "").split(',').map(v => v.trim()).filter(Boolean),
          subCategory: hasSubCategory,
          ...(hasSubCategory && { categoryInfo: processCategoryInfo(category.categoryInfo) })
        };
      });
    };

    let payload = {
      role: data?.role?.split(','),
      projectName: data.projectName,
      language: data.language.split(",").map((lang) => lang.trim()),
      updatedBy: data.updatedBy,
      fields: Object.values(data?.fields || [])
        .filter(field => field)
        .map(field => {
          const hasSubCategory = field?.subCategory === "true";

          return {
            label: field.label,
            fieldType: field.fieldType,
            possibleValues: (field?.possibleValues || "").split(',').map(v => v.trim()).filter(Boolean),
            fieldConstraint: field?.fieldConstraint ? field.fieldConstraint.split(',') : [],
            ...field?.fieldType === "table" ? {
              rowMetadata: field?.rowMetadata?.map(o => {
                return {
                  ...o, rowConstraint: o?.rowConstraint?.split(",")
                }
              }) || [],
              columnMetadata: field?.columnMetadata?.map(o => {
                return {
                  ...o, columnConstraint: o?.columnConstraint?.split(",")
                }
              }) || [],
            } : {},
            constraintMetadata: field?.constraintMetadata || {},
            subCategory: hasSubCategory,
            ...(hasSubCategory && { categoryInfo: processCategoryInfo(field.categoryInfo) })
          };
        })
    };

    console.log("apiUpdateDataCollectionForm: ", payload);
    return this.request(this.api().updatedataCollectionForm, payload).urltransform(urldata).exec().log().get();
  },


  apiGetDataCollectionForm(data = {}) {
    //  debugger
    let payload = {
      // "isActive": true,
      ...data
    };
    return this.request(this.api().getDCForm, payload)
      .urltransform()
      .exec().log()
      .mapresults((response) => {

        let data = response.data;

        // let data = {
        //   "formName": "Test Form15feb",
        //   "role": ["digital-sakhi"],
        //   "projectId": ["1osdggtqlny6xso3"],
        //   "language": ["en"],
        //   "formDescription": "test",
        //   "fields": [
        //     {
        //       "_id": "1osdggtqlny6xso3",
        //       "label": "State",
        //       "fieldType": "checkbox",
        //       "possibleValues": ["Delhi", "Chennai", "Mumbai", "Hyderabad"],
        //       "fieldConstraint": ["mandatory"],
        //       "constraintMetadata": {},
        //       "subCategory": true,
        //       "categoryInfo": [
        //         {
        //           "parentValue": "Delhi",
        //           "followUp": true,
        //           "label": "where in delhi?",
        //           "fieldType": "radio",
        //           "possibleValues": ["Noida", "CP"],
        //           "fieldConstraint": ["mandatory"],
        //           "subCategory": true,
        //           "categoryInfo": [
        //             {
        //               "followUp": true,
        //               "label": "Where in Noida?",
        //               "fieldType": "radio",
        //               "possibleValues": ["N1", "N2", "N3"],
        //               "fieldConstraint": ["optional"],
        //               "constraintMetadata": {},
        //               "subCategory": false
        //             },
        //             {
        //               "followUp": false,
        //               "fieldConstraint": [],
        //               "constraintMetadata": {},
        //               "possibleValues": [],
        //               "subCategory": false
        //             }
        //           ],
        //           "constraintMetadata": {}
        //         },
        //         {
        //           "parentValue": "Chennai",
        //           "followUp": false,
        //           "categoryInfo": [
        //             {
        //               "followUp": "true",
        //               "label": "Name",
        //               "fieldType": "text",
        //               "fieldConstraint": "character"
        //             },
        //             {
        //               "followUp": "true",
        //               "label": "Name1",
        //               "fieldType": "text",
        //               "fieldConstraint": "character"
        //             },
        //             {
        //               "followUp": "true",
        //               "label": "Name2",
        //               "fieldType": "text",
        //               "fieldConstraint": "character"
        //             },
        //             {
        //               "followUp": "true",
        //               "label": "Name3",
        //               "fieldType": "text",
        //               "fieldConstraint": "character"
        //             }
        //           ],
        //           "fieldConstraint": [],
        //           "constraintMetadata": {},
        //           "possibleValues": [],
        //           "subCategory": false
        //         },
        //         {
        //           "parentValue": "Mumbai",
        //           "followUp": false,
        //           "fieldConstraint": [],
        //           "constraintMetadata": {},
        //           "possibleValues": [],
        //           "subCategory": false
        //         },
        //         {
        //           "parentValue": "Hyderabad",
        //           "followUp": true,
        //           "label": "Where in Hyderabad?",
        //           "fieldType": "select",
        //           "possibleValues": ["HYD1", "HYD2", "HYD3"],
        //           "fieldConstraint": ["optional"],
        //           "subCategory": false,
        //           "constraintMetadata": {}
        //         }
        //       ]
        //     },
        //     {
        //       "_id": "1osdggtqlny6xso4",
        //       "label": "Age",
        //       "fieldType": "text",
        //       "possibleValues": [],
        //       "fieldConstraint": ["numeric"],
        //       "constraintMetadata": {},
        //       "subCategory": false
        //     },
        //     {
        //       "_id": "1osdggtqlny6xso5",
        //       "label": "Occupation",
        //       "fieldType": "text",
        //       "possibleValues": [],
        //       "fieldConstraint": ["character"],
        //       "constraintMetadata": {},
        //       "subCategory": false
        //     },
        //     {
        //       "_id": "1osdggtqlny6xso6",
        //       "label": "Gender",
        //       "fieldType": "radio",
        //       "possibleValues": ["Female", "Male"],
        //       "fieldConstraint": ["mandatory"],
        //       "constraintMetadata": {},
        //       "subCategory": true,
        //       "categoryInfo": [
        //         {
        //           "parentValue": "Female",
        //           "followUp": false,
        //           "fieldConstraint": [],
        //           "constraintMetadata": {},
        //           "possibleValues": [],
        //           "subCategory": false
        //         },
        //         {
        //           "parentValue": "Male",
        //           "followUp": true,
        //           "label": "How many childern?",
        //           "fieldType": "radio",
        //           "possibleValues": ["2", "3", "4", "5"],
        //           "fieldConstraint": ["mandatory"],
        //           "subCategory": true,
        //           "constraintMetadata": {},
        //           "categoryInfo": []
        //         }
        //       ]
        //     }
        //   ]
        // }

        console.log({ apiGetDataCollectionForm: data });

        const processCategoryInfo = (categoryInfo = []) => {
          return categoryInfo.map(category => {
            const hasSubCategory = !!category.subCategory;
            const hasFollowUp = !!category.followUp;

            return {
              ...category,
              followUp: hasFollowUp,
              fieldConstraint: category?.fieldConstraint || [], // Already an array
              constraintMetadata: category?.constraintMetadata || {},
              possibleValues: category?.possibleValues || [], // Already an array
              subCategory: hasSubCategory,
              categoryInfo: hasSubCategory ? processCategoryInfo(category.categoryInfo) : []
            };
          });
        };

        return {
          formName: data.formName,
          role: data.role,
          language: data.language,
          projectId: data.projectId,
          formDescription: data.formDescription,
          updatedBy: data.updatedBy,
          disable: data?.disable,
          fields: (data?.fields || []).map((field) => ({
            id: field._id,
            label: field.label,
            fieldType: field.fieldType,
            updatedBy: field.updatedBy,
            fieldConstraint: field.fieldConstraint || [], // Keep as an array
            constraintMetadata: field.constraintMetadata || {},
            rowMetadata: field?.rowMetadata || [],
            totalRows: field?.rowMetadata?.length || null,
            columnMetadata: field?.columnMetadata || [],
            totalCols: field?.columnMetadata?.length || null,
            possibleValues: field.possibleValues || [], // Keep as an array
            subCategory: !!field.subCategory,
            categoryInfo: Array.isArray(field.categoryInfo) ? processCategoryInfo(field.categoryInfo) : []
          })
          )
        };

      }).get();
  },

  apiGetDataCollectionFormResponse(data = {}, urldata = {}) {
    // console.log({ apiGetDataCollectionFormResponse: data, urldata });
    let payload = {
      // "isActive": true,
      ...data
    };
    return this.request(this.api().getDCFormResponse, payload)
      .urltransform(urldata)
      .exec().log()
      .mapresults(({ data, total }) => {
        return {
          data: data.items[0]?.records,
          total: data.items[0].pagination[0]?.totalRecords
        };
      }).get();
  },

  apiGetDataCollectionFormResponseDetails(urldata = {}) {
    return this.request(this.api().getDCFormResponseDetails)
      .urltransform(urldata)
      .exec().log()
      .mapresults((res) => {
        return res?.data
      }).get();
  },

  apiDeleteDCForm(urldata) {
    const payload = {
      isActive: false
    };

    return this.request(this.api().deleteDCForm, payload)
      .urltransform(urldata)

      .exec().log().get();
  },
  apiDisableDCForm(urldata) {
    const payload = {
      disable: true
    };
    return this.request(this.api().disableDCForm, payload)
      .urltransform(urldata)
      .exec().log().get();
  },
  apiEnableDCForm(urldata) {
    const payload = {
      disable: false
    };
    return this.request(this.api().enableDCForm, payload)
      .urltransform(urldata)
      .exec().log().get();
  },
  apiDcDashboardData(data = {}) {
    return this.request(this.api().dcdashboardData, data)
      .exec()
      .log()
      .get();
  },
  apiDCFileUploader(data, urldata = mixins.params()) {
    console.log({ filedata: data });
    return this.request(this.api().fileuploader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
  apiDCFileDownloader(data, urldata = mixins.params()) {
    console.log({ filedata: data });
    return this.request(this.api().fileDownloader, data)
      .urltransform(urldata)
      .multipart()
      .exec()
      .log()
      .get();
  },
}