import { useEffect } from "react";
import { useState } from "react";

function filterDcConstraints(props, field) {
  let constraints = _enum.FIELD_DC_CONSTRAINTS;
  let FIELD_DC_CONSTRAINTS = [];
  let isFreeText = ["text", "textarea"].includes(field?.fieldType);
  let isDate = ["date"].includes(field?.fieldType);
  if (isFreeText) {
    FIELD_DC_CONSTRAINTS = constraints.exclude(
      ["future_dates_only", "past_dates_only"],
      "key"
    );
  } else if (isDate) {
    FIELD_DC_CONSTRAINTS = constraints.only(
      ["mandatory", "future_dates_only", "past_dates_only"],
      "key"
    );
  } else {
    FIELD_DC_CONSTRAINTS = constraints.only(["mandatory", "optional"], "key");
  }
  return FIELD_DC_CONSTRAINTS;
}

function hasPossibleOptions(props, field) {
  return ["select", "checkbox", "radio"].includes(field?.fieldType);
}

function handleInputChange(props, field, name, node, event) {
  console.log("handleInputChange", event);
  let value = "";
  if (["select"].includes(node.type)) {
    value = node.multi
      ? (event || []).map((o) => o?.key)
      : (event || [])[0]?.key;
  } else {
    value = event.target.value;
  }
  let key = name.split(".").reverse()[0];

  if (key == "possibleValues") {
    value = value
      .split(",")
      .filter((v) => v)
      .join(",");
  }

  node.setData((state) => ({ ...state, [key]: value }));
}

const FiledRow = connect((props) => {
  const index = props.index;
  const disabled = Object.values(_enum.MANDATORY_DC_OBJS).map((o) => o.name);
  const parentIndex = props.parentIndex;
  const [field, setField] = useState(props.field);
  useEffect(() => {
    setField(props.field);
  }, [props.field]);
  const FIELD_DC_CONSTRAINTS = filterDcConstraints(props, field);
  const node = { data: field, setData: setField };
  const canEdit = !(
    props.urlparams.readonly || disabled.includes(field.name || field.label)
  );
  // useEffect(() => {
  //   console.log({ data: field });
  // }, [field]);
  props.setNode(
    {
      props,
      field,
    },
    "TableFieldRow" + index + parentIndex
  );

  const getName = (key) => `fields.${parentIndex}.rowMetadata.${index}.${key}`;
  return (
    <>
      <div className="flex flex-column">
        <div className="flex flex-wrap gap-4 bg-gray-200 px-2" key={field._id}>
          <AppInput
            label={`Row ${index + 1} Label`}
            type="text"
            name={getName("label")}
            required={true}
            defaultValue={field?.label || ""}
            readOnly={!canEdit}
          ></AppInput>
          {FIELD_DC_CONSTRAINTS && (
            <AppInput
              label="Constraints"
              type="select"
              name={getName("rowConstraint")}
              className="grow"
              required={true}
              multi={true}
              options={FIELD_DC_CONSTRAINTS}
              defaultValue={field?.rowConstraint || []}
              onChange={(...args) =>
                handleInputChange(
                  props,
                  field,
                  getName("rowConstraint"),
                  { type: "select", multi: true, ...node },
                  ...args
                )
              }
            ></AppInput>
          )}
        </div>
      </div>
    </>
  );
}, "TableFieldRow");
const FiledCol = connect((props) => {
  const index = props.index;
  const disabled = Object.values(_enum.MANDATORY_DC_OBJS).map((o) => o.name);
  const parentIndex = props.parentIndex;
  const [field, setField] = useState(props.field);
  useEffect(() => {
    setField(props.field);
  }, [props.field]);
  const FIELD_DC_CONSTRAINTS = filterDcConstraints(props, field);
  const node = { data: field, setData: setField };
  const canEdit = !(
    props.urlparams.readonly || disabled.includes(field.name || field.label)
  );
  // useEffect(() => {
  //   console.log({ data: field });
  // }, [field]);
  props.setNode(
    {
      props,
      field,
    },
    "TableFieldRow" + index + parentIndex
  );
  const getName = (key) =>
    `fields.${parentIndex}.columnMetadata.${index}.${key}`;
  return (
    <>
      <div className="flex flex-column">
        <div className="flex flex-wrap gap-4 bg-gray-200 px-2" key={field._id}>
          <AppInput
            label={`Column ${index + 1} Label`}
            type="text"
            name={getName("label")}
            required={true}
            defaultValue={field?.label || ""}
            readOnly={!canEdit}
          ></AppInput>
          {FIELD_DC_CONSTRAINTS && (
            <AppInput
              label="Constraints"
              type="select"
              name={getName("columnConstraint")}
              className="grow"
              required={true}
              multi={true}
              options={FIELD_DC_CONSTRAINTS}
              defaultValue={field?.columnConstraint || []}
              onChange={(...args) =>
                handleInputChange(
                  props,
                  field,
                  getName("columnConstraint"),
                  { type: "select", multi: true, ...node },
                  ...args
                )
              }
            ></AppInput>
          )}
        </div>
      </div>
    </>
  );
}, "TableFieldRow");

const createDefaultList = (length) => {
  let defaultlist = [];

  for (let i = 0; i < length; i++) {
    defaultlist.push({ id: +Math.random().toString().slice(2) });
  }

  return defaultlist;
};
export default connect(function (props) {
  // let defaultlist = [[{ _id: +Math.random().toString().slice(2) }]];

  let defaultrows = createDefaultList(Number(props?.field?.totalRows));
  let defaultcols = createDefaultList(Number(props?.field?.totalCols));

  const getRows = () => {
    console.log(props.field);
    if (Number(props?.field?.totalRows) !== props?.rowMetadata?.length) {
      return defaultrows;
    } else {
      return props?.rowMetadata;
    }
  };

  const getCols = () => {
    console.log(props.field);
    if (Number(props?.field?.totalCols) !== props?.columnMetadata?.length) {
      return defaultcols;
    } else {
      return props?.columnMetadata;
    }
  };

  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);

  useEffect(() => {
    setRows(getRows());
    console.log("defaults rows changed");
  }, [props?.field?.totalRows]);

  useEffect(() => {
    setCols(getCols());
    console.log("defaults cols changed");
  }, [props?.field?.totalCols]);

  useEffect(() => {
    if (
      props?.rowMetadata instanceof Array &&
      props?.rowMetadata?.length &&
      props?.rowMetadata?.length >= rows.length
    ) {
      setRows(props.rowMetadata);
    }
    if (
      props?.columnMetadata instanceof Array &&
      props?.columnMetadata?.length &&
      props?.columnMetadata?.length >= rows.length
    ) {
      setCols(props.columnMetadata);
    }
    console.log({ props, rows, defaultrows, defaultcols });
  }, [props.rowMetadata, props.columnMetadata]);

  let node = { rows, setRows, cols, setCols };
  props.setNode(node);

  return (
    <div className="row">
      <div className="col">
        <div className="flex">
          <h2 className="fs-5">Rows</h2>
        </div>
        <div className="row">
          {rows?.map((field, i) => (
            <div className="col-12">
              <FiledRow field={field} index={i} parentIndex={props.index} />
            </div>
          ))}
        </div>
      </div>
      <div className="col">
        <div className="flex">
          <h2 className="fs-5">Columns</h2>
        </div>
        <div className="row">
          {cols?.map((field, i) => (
            <div className="col-12">
              <FiledCol field={field} index={i} parentIndex={props.index} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}, "SubCatgoryList");
