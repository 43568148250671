import React, { Component } from "react";

export class MasterDataSearch extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("MasterDataSearch");
    this.form = React.createRef();
    this.state = {
      districts: [],
      search: {
        order_by: "updatedAt",
        order_direction: "DESC",
        page: 1,
      },
    };
    this.fields = [
      {
        name: "district",
        label: "District",
        type: "select",
        options: [],
        className: "select-focus",
        onChange: (...args) => this.handleDistrictChange(...args),
      },
      // {
      //   name: "cluster_code",
      //   label: "Cluster Code",
      //   type: "select",
      //   options: [],
      //   className: "select-focus",
      //   onChange: (...args) => this.handleClusterChange(...args),
      // },
    ];
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  get isManager() {
    return this.props.store.user?.employee_role === "manager";
  }

  onSearch(e, data, reset = false) {
    console.log("data on search", data, reset);
    if (data !== undefined) {
      this.props.onSearch && this.props.onSearch(data);
    } else {
      console.log("undefined onSearch");
      this.props.onSearch && this.props.onSearch({ district: "" });
    }
  }

  handleDistrictChange([value]) {
    console.log({ handleDistrictChange: value });
  }

  handleClusterChange([value]) {}

  componentDidMount() {
    this.fetchDistrict();
  }

  fetchDistrict() {
    let payload = {
      ...this.state.search,
    };
    this.api.getDistrict(payload).then((res) => {
      if (this.isAdmin || this.isManager) {
        this.setState({ districts: res.data });
      } else {
        let dt = this.props.store.user.district;
        let data = res?.data?.filter((r) => r.district === dt);
        this.setState({ districts: data });
      }
    });
  }

  updateFields(fields) {
    fields.find((o) => o.name === "district").options = this.state.districts
      .map((v) => v.district)
      .options();
    return fields;
  }

  resetSearchForm() {
    this.refs.form.form.current.reset();
    this.onSearch(true);
  }

  render() {
    let {} = this.state;
    let { fields } = this;
    fields = this.updateFields(fields);
    return (
      <>
        <AppForm
          className="row filter-row"
          ref="form"
          onSubmit={(...args) => this.onSearch(...args)}
        >
          {this.props.hideStatus
            ? fields
                .filter((v) => v.name !== "status")
                .map((field, i) => (
                  <div className="col-sm-6 col-md-2" key={i}>
                    <AppInputFocus {...field}></AppInputFocus>
                  </div>
                ))
            : fields.map((field, i) => (
                <div className="col-sm-6 col-md-2" key={i}>
                  <AppInputFocus {...field}></AppInputFocus>
                </div>
              ))}
          <div className="col-sm-6 col-md-2">
            <button
              className="btn btn-success btn-block w-100"
              ref="submitbtn"
              // onClick={(...args) => this.onSearch(...args)}
            >
              Search
            </button>
          </div>
        </AppForm>
        <button
          type="button"
          className="btn clear_filter_btn ml-auto d-block mb-3"
          onClick={(...args) => this.resetSearchForm(...args)}
        >
          <i class="fa fa-times-circle-o me-2"></i>
          Clear Filters
        </button>
      </>
    );
  }
}

export default connect(MasterDataSearch);
