import { useEffect } from "react";
import { useState } from "react";

function filterDcConstraints(props, field) {
  let constraints = _enum.FIELD_DC_CONSTRAINTS;
  let FIELD_DC_CONSTRAINTS = [];
  let isFreeText = ["text", "textarea"].includes(field?.fieldType);
  let isDate = ["date"].includes(field?.fieldType);
  if (isFreeText) {
    FIELD_DC_CONSTRAINTS = constraints.exclude(
      ["future_dates_only", "past_dates_only"],
      "key"
    );
  } else if (isDate) {
    FIELD_DC_CONSTRAINTS = constraints.only(
      ["mandatory", "future_dates_only", "past_dates_only"],
      "key"
    );
  } else {
    FIELD_DC_CONSTRAINTS = constraints.only(["mandatory"], "key");
  }
  return FIELD_DC_CONSTRAINTS;
}

function hasPossibleOptions(props, field) {
  return ["select", "checkbox", "radio"].includes(field?.fieldType);
}

function handleInputChange(props, field, name, node, event) {
  console.log("handleInputChange", event);
  let value = "";
  if (["select"].includes(node.type)) {
    value = node.multi
      ? (event || []).map((o) => o?.key)
      : (event || [])[0]?.key;
  } else {
    value = event.target.value;
  }
  let key = name.split(".").reverse()[0];

  if (key == "possibleValues") {
    value = value
      .split(",")
      .filter((v) => v)
      .join(",");
  }

  node.setData((state) => ({ ...state, [key]: value }));
}

const FiledRow = connect((props) => {
  const index = props.index;
  const disabled = Object.values(_enum.MANDATORY_DC_OBJS).map((o) => o.name);
  const parentIndex = props.parentIndex;
  const [field, setField] = useState(props.field);
  useEffect(() => {
    setField(props.field);
  }, [props.field]);
  const FIELD_DC_CONSTRAINTS = filterDcConstraints(props, field);
  const needPossibleOptions = hasPossibleOptions(props, field);
  let isLength = (field?.fieldConstraint || []).includes("length");
  const node = { data: field, setData: setField };
  const canEdit = !(
    props.urlparams.readonly || disabled.includes(field.name || field.label)
  );
  useEffect(() => {
    console.log({ data: field });
  }, [field]);
  props.setNode(
    {
      props,
      field,
    },
    "SubCategoryFieldRow" + index + parentIndex
  );
  const deleteRow = (e, index) => {
    props.onDelete && props.onDelete(e, index);
  };
  const getName = (key) => `fields.${parentIndex}.categoryInfo.${index}.${key}`;
  return (
    <>
      <div className="flex flex-wrap gap-4 bg-gray-200 px-2" key={field._id}>
        <AppInput
          label="Field Label"
          type="text"
          name={getName("label")}
          className="grow"
          required={true}
          defaultValue={field?.label}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              getName("label"),
              { type: "text", ...node },
              ...args
            )
          }
        ></AppInput>
        <AppInput
          label="Field Type"
          type="select"
          name={getName("fieldType")}
          options={_enum.FIELD_DC_TYPES}
          className="grow"
          required={true}
          defaultValue={field?.fieldType}
          onChange={(...args) =>
            handleInputChange(
              props,
              field,
              getName("fieldType"),
              { type: "select", ...node },
              ...args
            )
          }
        ></AppInput>
        {needPossibleOptions && (
          <AppInput
            label="Possible Values"
            type="text"
            name={getName("possibleValues")}
            className="grow"
            required={true}
            note="possible value should be comma separated"
            defaultValue={field?.possibleValues}
            onChange={(...args) =>
              mixins.debounce(() =>
                handleInputChange(
                  props,
                  field,
                  getName("possibleValues"),
                  { type: "text", ...node },
                  ...args
                )
              )
            }
          ></AppInput>
        )}
        {FIELD_DC_CONSTRAINTS && (
          <AppInput
            label="Field Constraints"
            type="select"
            name={getName("fieldConstraint")}
            className="grow"
            required={true}
            multi={true}
            options={FIELD_DC_CONSTRAINTS}
            defaultValue={field?.fieldConstraint || []}
            onChange={(...args) =>
              handleInputChange(
                props,
                field,
                getName("fieldConstraint"),
                { type: "select", multi: true, ...node },
                ...args
              )
            }
          ></AppInput>
        )}
        {isLength && (
          <>
            <AppInput
              label="Min"
              type="number"
              name={getName("constraintMetadata.length.min")}
              className="mw-100px"
              defaultValue={field?.constraintMetadata?.length?.min || ""}
              readOnly={!canEdit}
            ></AppInput>
            <AppInput
              label="Max"
              type="number"
              name={getName("constraintMetadata.length.max")}
              className="mw-100px"
              defaultValue={field?.constraintMetadata?.length?.max || ""}
              readOnly={!canEdit}
            ></AppInput>
          </>
        )}
        {index != 0 ? (
          <button
            type="button"
            onClick={(e) => deleteRow(e, index)}
            className="btn hover:bg-red-800 bg-red-500 m-auto mb-2 text-white"
          >
            <i className="fa fa-trash-o text-error" />
          </button>
        ) : (
          <span className="inline-block w-12 h-12"></span>
        )}
      </div>
    </>
  );
}, "SubCategoryFieldRow");

const addRow = ({ rows, setRows, event }) => {
  let item = [{ _id: +Math.random().toString().slice(2) }];
  setRows((state) => [...state, item]);
};

const deleteRow = (e, index, { rows, setRows, setRender, event }) => {
  setRows((rows) => {
    return [...rows.filter((v, i) => i != index)];
  });
};

export default connect(function (props) {
  let defaultlist = [[{ _id: +Math.random().toString().slice(2) }]];
  const getList = () =>
    (props?.list?.length ? props?.list : defaultlist) || defaultlist;
  const [rows, setRows] = useState(getList());
  useEffect(() => {
    if (
      props.list instanceof Array &&
      props.list.length &&
      props.list.length >= rows.length
    ) {
      setRows(props.list);
    }
  }, [props.list]);
  let node = { rows, setRows };
  props.setNode(node);
  return (
    <>
      {rows.map((field, i) => (
        <>
          <FiledRow
            field={field[0]}
            index={i}
            parentIndex={props.index}
            onDelete={(e, index) => deleteRow(e, index, { ...node, event: e })}
          />
        </>
      ))}
      <div className="bg-gray-200 px-2 rounded-b-lg flex">
        {!props.urlparams.readonly && (
          <button
            className="btn bg-transparent flex gap-2 text-primary ml-auto"
            type="button"
            onClick={(e) => addRow({ rows, setRows, event: e })}
          >
            <i className="fa fa-plus m-auto" />
            <span className="font-bold">Add More</span>
          </button>
        )}
      </div>
    </>
  );
}, "SubCatgoryList");
