import React, { Component } from "react";
import LayoutWrapper from "../LayoutWrapper";
import CategoryFields from "./CategoryFields";
import { Link } from "react-router-dom";
import "./datacollectioninfo.scss";

export class DataCollectionForm extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("DataCollectionForm");
    this.state = {
      rendered: 0,
      form: {
        language: "en",
        fields: Object.values(_enum.MANDATORY_DC_OBJS).map((o) => ({
          ...o,
          fieldConstraint: o.fieldConstraint.split(","),
        })),
      },
      disabled: _enum.MANDATORY_DC_FIELDS,
      projectlists: [],
      mounted: false,
      rendered: false,
      projectnamesearch: {
        project_status: "open,in_progress",
      },
      selectedProjectName: "",
    };
  }

  get has_edit() {
    return !!this.props.urlparams.id;
  }

  get isAdmin() {
    return this.props.store.user?.employee_role === "admin";
  }

  convertLengthToNumbers(obj) {
    if (typeof obj !== "object" || obj === null || obj === undefined) {
      return;
    }

    if (obj.constraintMetadata && obj.constraintMetadata.length) {
      obj.constraintMetadata.length.min = Number(
        obj.constraintMetadata.length.min
      );
      obj.constraintMetadata.length.max = Number(
        obj.constraintMetadata.length.max
      );
    }
  }

  sumitForm(e, data, form) {
    let parsedPayload = parseInput(data);
    console.log({ parsedPayload, data });
    parsedPayload.fields.forEach((field) => {
      if (field !== null) {
        this.convertLengthToNumbers(field);
      }
    });

    let payload = {
      ...parsedPayload,
      projectId: parsedPayload?.projectId.split(","),
    };
    if (!parsedPayload.language) {
      alert("Atleast one language should be selected");
      return;
    }
    console.log({ payload });
    if (this.mixin.params().id) {
      this.api.apiUpdateDataCollectionForm(payload).then(() => {
        this.navigate("/datacollection");
      });
    } else {
      this.api.apiCreateDataCollectionForm(payload).then(() => {
        this.navigate("/datacollection");
      });
    }
  }

  componentDidMount() {
    if (this.mixin.params().id) {
      this.api.apiGetDataCollectionForm().then((data) => {
        this.api
          .apiGetProjectById({ project_id: data?.projectId })
          .then((res) => {
            data.fields = data.fields.map((field) => {
              let name = Object.values(_enum.MANDATORY_DC_OBJS).find(
                (o) => o.label == field.label
              )?.name;
              field.name = name ? name : field.name;
              field.fieldConstraint = field?.fieldConstraint;
              return field;
            });
            this.setState({
              form: data,
              selectedProjectName: res.project_name,
              mounted: true,
            });
          });
      });
    } else {
      this.setState({
        mounted: true,
      });
    }
    this.getProjectList();
  }
  getProjectList() {
    this.props.api
      .apiGetProjectsList(this.state.projectnamesearch)
      .then(({ data, total }) => {
        this.setState({
          projectlists: data.options("project_id", "project_name"),
        });
      });
  }

  render() {
    let { props, has_edit, isAdmin } = this;
    let { form, selectedProjectName } = this.state;
    return (
      <LayoutWrapper
        title="Data Collection Form"
        back="Dashboard"
        backlink="/datacollection"
      >
        <AppForm
          ref="form"
          className="flex flex-col gap-4"
          onSubmit={(...args) => this.sumitForm(...args)}
        >
          <div className="flex flex-wrap gap-4">
            <div style={{ flex: "0.3" }}>
              <AppInput
                type="text"
                label="Form Label"
                name="formName"
                required={true}
                // maxLength="50"
                pattern="^[A-Za-z][a-zA-Z0-9\/ \\ ]*$"
                invalidmessage="Value Should be Valid Aphanumeric Content"
                defaultValue={form?.formName}
                readonly={props.urlparams.readonly || has_edit}
              ></AppInput>
              <AppInput
                type="select"
                label="Role"
                name="role"
                options={_enum.APPLICANT_ROLES}
                multi={true}
                required={true}
                defaultValue={form?.role || []}
                readonly={props.urlparams.readonly}
              ></AppInput>
              <AppInput
                type="select"
                label="Project Name"
                name="projectId"
                options={this.state.projectlists || []}
                multi={true}
                required={true}
                defaultValue={form?.projectId || []}
                readonly={props.urlparams.readonly || has_edit}
                {...(props.urlparams.readonly || has_edit
                  ? {
                      showtooltip: true,
                      tooltipvalue: selectedProjectName || "Tooltip",
                    }
                  : {})}
              ></AppInput>
              <AppInput
                type="select"
                label="Language"
                name="language"
                multi={true}
                options={_enum.APPLICANT_DC_LANGUAGE_LIST}
                required={true}
                defaultValue={form?.language?.toString().split(",") || []}
                readonly={props.urlparams.readonly}
              ></AppInput>
            </div>
            <div className="grow">
              <AppInput
                controlClass="!min-h-[220px]"
                type="textarea"
                label="Form Description"
                name="formDescription"
                required={true}
                // maxLength="250"
                // invalidmessage="Value Should Not Exceed 250 Characters"
                defaultValue={form?.formDescription}
                readonly={props.urlparams.readonly}
              ></AppInput>
            </div>
          </div>
          <div className="flex flex-wrap gap-4"></div>
          <div className="flex flex-col gap-4">
            <CategoryFields list={this.state.form?.fields || []} />
          </div>
          <div className="flex">
            <div className="grow"></div>
            <Link
              to={"/datacollection"}
              className="btn btn_primary grey ms-2 me-2"
            >
              Cancel
            </Link>
            {isAdmin && (
              <>
                {!props.urlparams.readonly && (
                  <>
                    <button className="btn btn-primary add-btn">SUBMIT</button>
                  </>
                )}
                {props.urlparams.readonly && (
                  <>
                    <a
                      className={Object.className({
                        "btn btn-primary add-btn": true,
                        hidden: !isAdmin,
                      })}
                      href={`${window.location.pathname}?id=${props.urlparams.id}`}
                    >
                      Edit
                    </a>
                  </>
                )}
              </>
            )}
          </div>
        </AppForm>
      </LayoutWrapper>
    );
  }
}

export default connect(DataCollectionForm);
